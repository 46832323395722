
@media only screen and (max-width: 1128px) {
    .header-container {
        background-color: #E9ECF2;
        box-shadow: 3px 3px 16px 0 rgba(0, 0, 0, 0.12);
        margin: 30px auto !important;
        margin-top: 0 !important;
    }
}

@media only screen and (min-width: 1128px) {
    .header-container {
        border-radius: 30px;
        background-color: #E9ECF2;
        box-shadow: 3px 3px 16px 0 rgba(0, 0, 0, 0.12);
        margin: 30px auto !important;
    }
}

.header-link {
    font-size: 14px;
    font-family: "Montserrat";
    color: "#9599B2";
}