html, h6, .h6, small, .text-sm {
    line-height: 20px !important;
}

.white-36 {
    color: #FFFFFF;
}

.hero-shadow {
    text-shadow: 0px 3px 4px #4D16205F;
}

.features-tiles-item-content {
    background: #DDE2EC 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 10px #50468E29;
    border-radius: 0px 0px 20px 20px;
    opacity: 1;
    height: 200px;
    font-size: 20px;
}

.features-tiles-item-content p {
    text-align: justify;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 20px;
}


.image-tiles {
    border-radius: 20px 20px 0 0;
}

.features-tiles-item-image {
    display: block !important;
}

.valores-container {
    background: #DDE2EC 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 10px #50468E29;
    border-radius: 20px;
    opacity: 1;
}

.valores-tiles-item-content {
    text-align: justify;
    letter-spacing: 0.5px;
    color: #9599B2;
    opacity: 1;
    padding: 20px;
    font-size: 16px;
    display: table;
}

.valores-tiles-item-content p, .content-text-justify {
    text-align: justify;
}

.valores-title {
    text-align: center;
    font: normal normal bold 30px Montserrat;
    letter-spacing: 0px;
    color: #4353B3;
    margin-bottom: 0;
}

.line-service {
    margin: 30px 0;
}

.line-valores {
    margin: 10px 0;

}

.valores-image {
    padding-top: 30px;
}

.split-wrap .split-item .split-item-content {
    margin: 30px !important;
}

.desc-body {
    line-height: 1.5;
    letter-spacing: 0.4px;
}

.services-tiles-item-content {
    text-align: start;
    letter-spacing: 0.5px;
    color: #9599B2;
    opacity: 1;
    padding: 20px;
    font-size: 16px;
    display: table;
}

.text-service {
    display:table-cell;
    vertical-align:middle;
    padding-left: 10px;
}

.num-service {
    font: normal normal bold 45px/55px Montserrat;
    color: #53597E;
    font-size: 35px !important;
    font-family: "Montserrat";
}

.services-container {
    background: #DDE2EC 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 10px #50468E29;
    border-radius: 20px;
    opacity: 1;
    padding: 0px 20px !important;
}

.cta-inner {
    background-color: #E9ECF2 !important;
    background-image: unset !important;
}

.cta-image {
    border-radius: 20px;
}

.cta-action {
    padding: 32px;
    text-align: justify;
}

.cta-text {
    font-style: normal; font-variant: normal;
}

.alianzas-left {
    font: normal normal bold 24px/29px Montserrat;
    color: #9599B2;
}
.alianzas-right {
    font: normal normal bold 24px/29px Montserrat;
    color: #24283E;
}

.quote-image {
    margin-left: 0 !important;
}

.testimonio-container {
    padding: 35px !important;
}

.testimonio-name {
    padding-top: 35px;
    font-size: 20px !important;
    font-family: Montserrat;
    color: #41A33D;
    font-weight: bold;
}

.footer-text {
    font-size: 16px;
    color: #9599B2;
    font-family: "Montserrat";
    margin-left: 20px;
    margin-bottom: 0;
}

.footer-box {
    display: flex;
    align-items: center;
    width: 50%;
}

.primary-1 {
    color: #24283E;
    text-align: center;
    padding-top: 20px;
}

.footer-img {
    background-image: url(/static/media/footer_2x.a6a97064.png);
    position: absolute;
    width: 100%;
    max-width: 100%;
    background-position: center top;
    background-size: cover;
}

.illustration-section-footer {
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-image: url(/static/media/footer_2x.a6a97064.png);
    background-repeat: no-repeat;
    background-size: contain;
    left: 50%;
    top: 0;
    background-position: center top;
    transform: translate(-50%);
}

section {
    padding-top: 100px !important;
}

h1 {
    font-size: 52px !important;
}

.content-text {
    font-size: 18px !important;
    font-weight: 400 !important;
    text-align: justify !important;
}

@media (min-width: 1128px) {
    .image-us-right {
        border-radius: 0 20px 20px 0;
    }
    
    .image-us-left {
        border-radius: 20px 0 0 20px;
    }

    .split-item {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
        padding: 0 !important;
        box-shadow: 0px 6px 10px #50468E29;
        border-radius: 20px;
        background-color: #DDE2EC;
    }

    section {
        padding-top: 140px !important;
    }
}

@media (max-width: 640px) {
    .illustration-section-01, .illustration-section-01::after {
        background-image: unset !important;
    }

    .footer-box img{
        margin-left: 0 !important;
        margin-right: 0 !important;

    }

    .footer-box {
        width: 100%;
        justify-content: space-between;
    }

    h1 {
        font-size: 44px !important;
    }

    .content-text {
        font-size: 16px !important;
    }
}


@media (min-width: 640px) and (max-width: 1128px) {
    .container .split-wrap .split-item .split-item-content  {
        width: 50% !important;
    }

    .image-us-left, .image-us-right {
        width: 40vw !important;
    }

    .image-us-left {
        margin-left: auto;
    }

    .image-us-right {
        margin-left: 0 !important;
    }

}

@media (min-width: 640px) {
    .cta-slogan {
        float: left;
    }
    
    .cta-action {
        padding: 32px;
        padding-left: 232px;
        min-height: 200px;
    }
    .cta-inner {
        background: #DDE2EC 0% 0% no-repeat padding-box;
        box-shadow: 0px 6px 10px #50468E29;
        border-radius: 20px;
        padding: 0 !important;
        margin-bottom: 90px;
    }

    .cta-image {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
    }

    

}

@media (min-width: 750px) {
    .testimonio-name {
        /*position: absolute;
        bottom: 60px;
        left: 0;
        right: 0px;*/
    }
}

.content-text-xs {
    font-size: 14px !important;
    font-weight: 400 !important;
}





.hero.section {
    padding-top: 140px !important;
}



.section-inner {
    padding-bottom: 0 !important;
    padding-top: 0 !important;

}

footer {
    margin-top: 100px;
}

.testimonio-desc {
    font-size: 16px !important;
}